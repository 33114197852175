import React from "react"
import logo from "../images/logo.png"
import { FaAlignRight } from "react-icons/fa"
import PageLinks from "./NavLinks"
import Sticky from 'react-sticky-el'
import Indicator from "../components/Indicator"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'

const Navbar = ({toggleSidebar, navVersion, indicator, determineTheme,  themeSwitchButton, dark}) => {
    return <nav className={`nav-${navVersion} nav-main`}>
        <Sticky>
          <div className="nav-center">
            <div className="nav-header">
              <a href="/">
              <img src={logo} alt="logo"/>
              </a>
              { indicator &&
                <Indicator/>
              }
              <button type="button" className="toggle-btn" onClick={toggleSidebar}>
                <FaAlignRight></FaAlignRight>
              </button>
            </div>
            <PageLinks styleClass="nav-links"></PageLinks>
            {themeSwitchButton &&
            <div className="theme-switcher btn">
              <div onClick={determineTheme} className="click"></div>
              <label id="switch" className="switch">
              <input type="checkbox"/>
              {
                dark === true ? 
                <FontAwesomeIcon icon={faSun}/> 
                : <FontAwesomeIcon icon={faMoon}/>
              }
              </label>
            </div>
          }
          </div>
        </Sticky>
      </nav>
}

export default Navbar
