import React, { useState, useEffect } from "react"
import Navbar from "./Navbar"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import Progress from './Progress'

const Layout = ({ children , navVersion, indicator, themeSwitch}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dark, setDark] = React.useState(false);

  const determineTheme = () => {
    setDark(!dark);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [])

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Progress isAnimating={isLoading} animationDuration={300}
  incrementDuration={500}/>
      { !isLoading &&
        <>
          <Navbar indicator={indicator} navVersion={navVersion} determineTheme={determineTheme} toggleSidebar={toggleSidebar} themeSwitchButton={themeSwitch} dark={dark}/>
          <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar}/>
            <div className={`${dark === true ? 'page-content-dark' : 'page-content'}`}>
              {children}
            </div>
          <Footer/>
        </>
      }
    </>
  )
}

export default Layout
